<template>
	<div :ref="block.id" class="block-inner">
		<header ref="content" @click="handleOptionClick" @change="sendAnswerText" v-html="blockContent" />
	</div>
</template>

<script>
	import {jsonToHtml} from '../../inc/text';
	import Bus from '../../inc/bus';
	import {shuffleArray} from '../../inc/utils';
	import answerMixin from '../../mixins/answerMixin';
	import {showCourseFeedback} from '../../inc/courseUtils';
	import Store from '../../inc/store';

	let addedListener = false;

	export default {
		name: 'BlockOpenCloze',
		mixins: [answerMixin],
		props: {
			block: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				activeElement: null
			};
		},
		computed: {
			showFeedback() {
				return showCourseFeedback(this.block.type);
			},
			answer() {
				return Store.groupAnswers.find(answer => answer.blockId === this.block.id) || {};
			},
			blockContent() {
				return this.convertOptions(jsonToHtml(this.block.content));
			}
		},
		watch: {
			showFeedback(show) {
				this.setOptionGroupOutline(show);
			},
			blockContent() {
				if(this.activeElement !== null) {
					const input = Array.from(this.$refs.content.querySelectorAll('input'))[this.activeElement];

					if(input) {
						input.focus();
					}
				}
			}
		},
		destroyed() {
			Bus.off('clickAnywhere', this.toggleOptionDropdown);
			addedListener = false;
		},
		mounted() {
			if(!addedListener) {
				Bus.on('clickAnywhere', this.toggleOptionDropdown);
				addedListener = true;
			}

			this.setOptionGroupOutline(this.showFeedback);
		},
		methods: {
			toggleOptionDropdown(e) {
				let open = false;
				let target = e.target;

				if(target.matches('.options:not(.open)')) {
					open = true;
				}
				else if(target.matches('.options:not(.open) .options__selected')) {
					open = true;
					target = e.target.parentElement;
				}

				document.querySelectorAll('.options.open').forEach(options => {
					options.classList.remove('open');
				});

				if(open) {
					target.classList.add('open');
				}
			},
			convertOptions(html) {
				const parser = new DOMParser();
				const doc = parser.parseFromString(html, 'text/html');
				const {sortOptions, freetextAnswers} = this.block.meta;

				if(freetextAnswers) {
					doc.querySelectorAll('.options').forEach(element => {
						const input = doc.createElement('input');

						input.setAttribute('type', 'text');
						input.setAttribute('class', 'options__selected');
						input.setAttribute('value', this.getAnswer(element.dataset.id));

						element.appendChild(input);
						element.classList.add('freetext');
					});
				}
				else {
					doc.querySelectorAll('.options').forEach(element => {
						if(!this.block.options[element.dataset.id]) {
							return;
						}

						let ops = JSON.parse(JSON.stringify(this.block.options[element.dataset.id]));

						if(sortOptions === 'random') {
							ops = shuffleArray(ops);
						}
						else if(sortOptions === 'alpha') {
							ops.sort((a, b) => {
								if(a.label < b.label) {
									return -1;
								}
								if(a.label > b.label) {
									return 1;
								}

								return 0;
							});
						}

						const selected = doc.createElement('span');
						const selectedOp = ops.find(op => this.hasAnswerOption(op.id));

						if(selectedOp) {
							selected.textContent = selectedOp.label;
							selected.setAttribute('data-id', selectedOp.id);
						}
						else {
							selected.textContent = '-- choose --';
							selected.setAttribute('data-id', '');
						}

						selected.className = 'options__selected';
						element.appendChild(selected);
						const list = doc.createElement('span');

						list.className = 'options__dropdown';

						ops.forEach(option => {
							const item = doc.createElement('span');

							item.dataset.id = option.id;
							item.className = 'options__item';
							item.appendChild(doc.createTextNode(option.label));
							list.appendChild(item);
						});

						element.appendChild(list);
					});
				}

				return doc.documentElement.innerHTML;
			},
			setOptionGroupOutline(visible) {
				this.$nextTick(() => {
					this.$refs[this.block.id].querySelectorAll('.options').forEach(el => {
						if(visible) {
							let group = null;
							let isGroupCorrect = false;

							if(this.answer && this.answer.answers && this.answer.answers.length) {
								group = this.answer.answers.find(a => a.optionGroup === el.dataset.id);
							}

							if(group) {
								if(group.options && this.answer.correctAnswers) {
									isGroupCorrect = group.options.filter(option => this.answer.correctAnswers.map(ca => ca.id).includes(option.id)).length > 0;
								}
								else if(group.value) {
									isGroupCorrect = this.answer.correctAnswers
										.filter(ca => ca.optionGroup === group.optionGroup)
										.map(ca => ca.value)
										.includes(group.value);
								}
							}

							if(isGroupCorrect) {
								el.style.boxShadow = '0px 0px 0px 2px #71B340';
							}
							else {
								el.style.boxShadow = '0px 0px 0px 2px #FF6B6B';
							}
						}
						else {
							el.style.boxShadow = '';
						}
					});
				});
			},
			handleOptionClick(e) {
				if(e.target.tagName === 'INPUT') {
					this.activeElement = Array.from(this.$refs.content.querySelectorAll('input')).indexOf(e.target);
				}
				else if(e.target.classList.contains('options__item')) {
					const group = e.target.parentElement.parentElement;
					const selected = group.querySelector('.options__selected');

					selected.dataset.id = e.target.dataset.id;
					selected.textContent = e.target.textContent;

					this.sendAnswerOptions(e);
				}
			},
			sendAnswerOptions(e) {
				if(this.block.meta.freetextAnswers) {
					return;
				}

				const fields = e.target.closest('.block-inner').querySelectorAll('.options');
				const answers = [];

				for(const field of fields) {
					const checked = field.querySelector('.options__selected');

					answers.push({
						optionGroup: field.dataset.id,
						options: checked && checked.dataset.id ? [{id: checked.dataset.id, label: checked.textContent}] : []
					});
				}

				this.sendAnswer(answers).then(() => this.setOptionGroupOutline(this.showFeedback));
			},
			sendAnswerText(e) {
				if(!this.block.meta.freetextAnswers) {
					return;
				}

				const fields = e.target.closest('.block-inner').querySelectorAll('.options.freetext');
				const answers = [];

				for(const field of fields) {
					const value = field.querySelector('input').value;

					answers.push({
						optionGroup: field.dataset.id,
						value
					});
				}

				this.sendAnswer(answers).then(() => this.setOptionGroupOutline(this.showFeedback));
			}
		}
	};
</script>

<style lang="scss" scoped>
	$options_line_padding: 4px 25px 6px 10px;

	header::v-deep {
		margin-bottom: 0 !important;
		font-weight: inherit !important;

		li {
			margin-bottom: 0.6em;
		}
	}

	.block-inner::v-deep {
		.options {
			position: relative;
			margin: 0 3px;
			min-width: 150px;
			display: inline-block;
			user-select: none;
			border-radius: $border_radius;

			&:not(.freetext) {
				cursor: pointer;
				background-color: $color__white;
				padding: $options_line_padding;
				box-shadow: $box_shadow__input;

				&::after {
					content: "";
					position: absolute;
					top: 50%;
					right: 8px;
					border: 5px solid transparent;
					border-top-color: $color__dark;
					transform: translateY(-15%);
				}
			}

			&.open {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;

				&::after {
					transform: translateY(-70%) rotate(180deg);
				}

				.options__dropdown {
					display: block;
				}
			}

			input.options__selected {
				margin-bottom: 0;
				font-size: 1em;
			}

			.options__dropdown {
				display: none;
				position: absolute;
				overflow: hidden;
				top: 100%;
				left: 0;
				width: fit-content;
				max-width: 100vw;
				z-index: 100;
				background-color: $color__white;
				border-bottom-left-radius: $border_radius;
				border-bottom-right-radius: $border_radius;
				box-shadow: $box_shadow__input;
			}

			.options__item {
				display: block;
				padding: $options_line_padding;
				white-space: nowrap;

				&:hover {
					background-color: $color__light_blue;
					color: $color__white;
				}
			}
		}
	}
</style>